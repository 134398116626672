// 财务信息
<template>
  <div class="financiaInfo">
    <div class="addBtn">
      <el-upload
                action=""
                class="upload-demo"
                :http-request="function (url) {
          return uploadFile(url);
        }"
                :show-file-list="false"
                accept=".xls,.xlsx"
                v-if="type!=='details'"
              >
                <base-button label="导入"  />
              </el-upload>
      <base-button label="新增"  v-if="type!=='details'" @click="addTableItem()" />
    </div>
    <div class="box" v-if="financialData.length>0">
      <div class="left">
        <div class="leftTop">
          <div>序号</div>
          <div>科目(元)/周期(年)</div>
        </div>
        <div
          v-for="(item, index) of leftConfig"
          :key="item.label"
          class="leftData"
        >
          <div>{{ index + 1 }}</div>
          <div>{{ item.label }}</div>
        </div>
      </div>
      <div class="right">
        <div v-for="(item, index) of configData" :key="index" class="rightBox">
          <div
            v-for="(elem, indexOn) of item"
            :key="indexOn"
            :class="{ rightTop: indexOn === 0 }"
          >
            <div v-if="!elem.add">
              {{ getKey(elem, indexOn) }}
            </div>
            <!-- <div v-if=" indexOn === 0">
              <el-date-picker
                v-model="elem[setKey(elem)]"
                type="year"
                value-format="timestamp"
                :readonly='!elem.add'
                placeholder="请选择年份"
              >
              </el-date-picker>
            </div> -->
            <div v-if="elem.add">
              <sz-input
                placeholder="请输入"
                v-model="elem[setKey(elem)]"
                @input="mInput"
              ></sz-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // 新增 -->
    <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="新增财务信息"
    >
      <base-form
        :componentList="fromConfig"
        :showBtns="false"
        :formAttrs="{
          model: addData,
          labelWidth: '120px',
        }"
        class="formStyle"
        ref="addFromData"
      />
      <template slot="footer">
        <base-button label="提 交" @click="submit" />
        <base-button
          label="关 闭"
          type="default"
          @click="visibleState = false"
        />
      </template>
    </base-dialog>
  </div>
</template>

<script>
import {
  analysisApi
} from '@/api/companyApi'
import { getHeaders } from '@/utils/auth'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { fromConfig } from '../utils/addFinancialInformation.js'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import SzInput from '@/components/input/sz-input/sz-input.vue'
export default {
  components: { SzInput, BaseButton, BaseDialog, BaseForm },
  props: {
    financialData: Array,
    type: String
  },
  data () {
    return {
      userUploadAccept: '.xlsx,.xls',
      uploadUserTemplateUrl:
        process.env.VUE_APP_BASE_URL +
        '/api/authcenter/base/user/import/ch/head/20',
      addData: {},
      visibleState: false,
      leftConfig: [
        { label: '货币资金', prop: 'cashEquivalents' },
        { label: '应收账款', prop: 'accountsReceivable' },
        { label: '其他应收款', prop: 'otherReceivables' },
        { label: '预付账款', prop: 'prepayment' },
        { label: '总资产合计', prop: 'totalAssets' },
        { label: '总负债合计', prop: 'totalLiability' },
        { label: '主营业务收入', prop: 'primeOperatingRevenue' },
        { label: '净利润', prop: 'netProfit' },
        { label: '净利润率(%)', prop: 'netProfitRate' },
        { label: '资产负债率(%)', prop: 'debtAssetRatio' },
        {
          label: '应收账款周转率(%)',
          prop: 'receivablesTurnoverRatio'
        },
        { label: '现金短债比', prop: 'cashRatio' }
      ],

      configData: [],
      headers: ''
    }
  },
  // 计算属性 类似于data概念
  computed: {
    fromConfig () {
      return fromConfig(this)
    },
    analysisApi () {
      return analysisApi
    }
  },
  // 监控data中的数据变化
  watch: {
    financialData: {
      handler (val) {
        this.configData = this.formatData(val)
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    // 文件上传
    uploadFile (param) {
      const formData = new FormData()
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = [
        'xls', 'xlsx']
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传xls,xlsx格式文件')
        return
      }
      formData.append('file', param.file)
      // formData.append('companyId ', this.$route.query.keyId)
      this.analysisApi.importFile(formData).then((res) => {
        if (res.code === '0') {
          this.success('文件上传成功')
          this.$emit('changeFinancialData', res.data)
          console.log(res.data)
        } else {
          this.error('文件上传失败')
        }
      })
    },

    submit () {
      this.$refs.addFromData.validate((valid) => {
        if (valid) {
          this.addData.year = (new Date(this.addData.year)).getFullYear()
          let pass = true
          this.financialData.forEach((item, index, arr) => {
            if (this.addData.year === item.year) {
              this.$set(arr, index, this.addData)
              pass = false
            }
          })
          if (pass) {
            this.financialData.push(this.addData)
          }
          this.visibleState = false
          this.configData = this.formatData(this.financialData)
          this.$emit('changeFinancialData', this.financialData)
        }
      })
    },

    setKey (item) {
      for (const key in item) {
        if (key !== 'add') {
          return key
        }
      }
    },
    mInput () {
      console.log(this.configData)
    },
    // 新增列
    addTableItem () {
      this.addData = {}
      this.visibleState = true
      this.$nextTick(() => {
        this.$refs.addFromData.clearValidate()
      })
    },
    getKey (item, index) {
      for (const key in item) {
        if (index !== 0) {
          return item[key]
        } else {
          return item[key]
        }
      }
    },
    formatData (arr) {
      const newArr = []
      arr.forEach((item, index) => {
        newArr.push([])
        for (const key in item) {
          let obj = {}
          obj = { [key]: item[key] }
          newArr[index].push(obj)
        }
      })
      return newArr
    }
  },
  // 生命周期 - 创建完成(可以访问当前this实例)
  created () {
    this.headers = getHeaders()
  },
  // 生命周期 - 挂载完成(可以访问DOM元素)
  mounted () {}
}
</script>
<style lang='scss' scoped>
.financiaInfo {
  padding: 10px;
}
.addBtn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background: #fff;
  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #6996FF;
    }
  }
}
.right {
  display: flex;
  max-width: calc(100%-235px);
  // overflow-x: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  .rightTop {
    background: #EBF1FF;
  }
  div {
    border: 1px solid #B6CCFF;
    border-left: 0;
    border-top: 0;
    text-align: center;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width: 308px;
  }
}
.box {
  display: flex;
}
.leftData {
  display: flex;
  div {
    border: 1px solid #B6CCFF;
    border-left: 0;
    border-top: 0;
    text-align: center;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 200px;
  }
}
.upload-demo{
  margin-right:10px ;
}
.leftTop {
  display: flex;
  div {
    background: #EBF1FF;
    font-size: 14px;
    border: 1px solid #B6CCFF;
    border-left: 0;
    border-top: 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 200px;
  }
}
</style>
