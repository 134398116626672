<template>
  <!-- 负债信息 -->
  <div>
    <div class="row">
      <div>
        <div class="title">
          <left-icon icon="iconfont iconrongzi" label="交发融资" />
          <div
            class="tabs"
            v-for="(item, index) of tabs1"
            :key="index"
            @click="active1 = index"
            :class="{ active: index === active1 }"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) of data1" :key="index">
            <div class="label">{{ item.finaaceProductName }}</div>
            <div class="value">
              {{
                item.loanAmount !== ""
                  ? parseFloat(item.loanAmount / 10000).toFixed(2).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2 }
                    )
                  : "--"
              }}
              <span style="font-size: 14px; color: #757380">万</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="title">
          <left-icon icon="iconfont icona-yuangonghetong1" label="基础交易合同" />
          <div
            class="tabs"
            v-for="(item, index) of tabs1"
            :key="index"
            @click="active2 = index"
            :class="{ active: index === active2 }"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) of data2" :key="index">
            <div class="label">{{ item.label }}</div>
            <div class="value">
              {{
                item.value !== ""
                  ? parseFloat(item.value).toFixed(2).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })
                  : "--"
              }}
              <span style="font-size: 14px; color: #757380">万</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="title">
          <left-icon icon="iconfont icona-fangdichan1" label="实控人资产" />
          <div
            class="tabs"
            v-for="(item, index) of tabs2"
            :key="index"
            @click="active3 = index"
            :class="{ active: index === active3 }"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) of data3" :key="index">
            <div class="label">{{ item.label }}</div>
            <div class="value">
              <span v-if="index === 0">{{ item.value }}</span>
              <span v-else>{{
                item.value !== ""
                  ? parseFloat(item.value / 10000).toFixed(2).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })
                  : "--"
              }}</span>
              <span style="font-size: 14px; color: #757380">{{
                index === 0 ? "笔" : "万"
              }}</span>
            </div>
          </div>
          <div class="time">数据最新更新时间：{{ modifyTime }}</div>
        </div>
      </div>
      <div>
        <div class="title">
          <left-icon icon="iconfont icona-zhaiquan1" label="实控人负债" />
          <div
            class="tabs"
            v-for="(item, index) of tabs3"
            :key="index"
            @click="active4 = index"
            :class="{ active: index === active4 }"
          >
            <div v-if="index === 0">{{ item.label }}</div>
            <div v-if="index === 1">
              近
              <el-input
                v-model="item.key"
                @input="monthCount"
                maxlength="3"
              ></el-input>
              月到期
            </div>
          </div>
        </div>
        <div class="content">
          <div v-for="(item, index) of data4" :key="index">
            <div class="label">{{ item.label }}</div>
            <div class="value">
              {{
                item.value !== ""
                  ? parseFloat(item.value).toFixed(2).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })
                  : "--"
              }}
              <span style="font-size: 14px; color: #757380">万</span>
            </div>
          </div>
          <div class="time">数据最新更新时间：{{ nextModifyTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/auth/common'
import Storage from '@/utils/storage'
import { businessManageApi } from '@/api/businessApi'
import LeftIcon from './left-icon.vue'
export default {
  components: {
    LeftIcon
  },
  props: {},
  data () {
    return {
      enterpriseId: Storage.getLocal('admittanceInfo').gysId,
      data1: [
        { finaaceProductName: '稳时付', loanAmount: '0' },
        { finaaceProductName: '稳时付', loanAmount: '0' },
        { finaaceProductName: '稳时付', loanAmount: '0' },
        { finaaceProductName: '稳时付', loanAmount: '0' }
      ],
      data2: [
        { label: '合同总额', value: '0', key: 'contractAmount' },
        { label: '累计产值', value: '0', key: 'outputTotalAmount' },
        { label: '累计已付', value: '0', key: 'paidAmount' },
        { label: '累计应付', value: '0', key: 'payableAmount' }
      ],
      data3: [
        { label: '资产笔数', value: '0', key: 'assetNum' },
        { label: '资产评估价值', value: '0', key: 'appraisalPrice' },
        { label: '资产涉及贷款余额', value: '0', key: 'loanBalance' }
      ],
      data4: [
        { label: '合计', value: '0', key: 'totalAmount' },
        { label: '长期负债', value: '0', key: 'longAmount' },
        { label: '短期负债', value: '0', key: 'shortAmount' },
        { label: '对外担保', value: '0', key: 'extAmount' },
        { label: '保函', value: '0', key: 'lgAmount' }
      ],
      active1: 0,
      active2: 0,
      active3: 0,
      active4: 0,
      tabs1: [
        { label: '不限融资时间', key: '0' },
        { label: '近1年', key: '1' },
        { label: '近2年', key: '2' },
        { label: '近3年', key: '3' }
      ],
      tabs2: [
        { label: '不限类型', key: '' },
        { label: '现金', key: 'CASH' },
        { label: '票据', key: 'BILL' },
        { label: '证券', key: 'SECURITIES' },
        { label: '不动产', key: 'REAL_ESTATE' },
        { label: '动产', key: 'PERSONAL_ESTATE' },
        { label: '其他', key: 'OTHER' }
      ],
      tabs3: [
        { label: '不限负债到期时间', key: '0' },
        { label: '', key: '3' }
      ],
      modifyTime: '',
      nextModifyTime: ''
    }
  },
  // 计算属性 类似于data概念
  computed: {
    api () {
      return businessManageApi
    }
  },
  // 监控data中的数据变化
  watch: {
    active1 (val) {
      this.getFinancingCount()
    },
    active2 (val) {
      this.getContractCount()
    },
    active3 (val) {
      this.getCompanyAssetCount()
    },
    active4 (val) {
      this.getCompanyLiabilityCount()
    }
  },
  // 方法集合
  methods: {
    // 月数修改
    monthCount () {
      this.tabs3[1].key = this.tabs3[1].key === '0' ? '' : this.tabs3[1].key.replace(/\D/g, '')
      if (this.active4 === 1) {
        this.getCompanyLiabilityCount()
      }
    },
    // 资产负债信息统计-稳诚融资接口
    getFinancingCount () {
      this.api
        .getFinancingCount({
          enterpriseId: this.enterpriseId,
          financeTimeYearCount: this.tabs1[this.active1].key
        })
        .then((res) => {
          for (const key in this.data1) {
            this.data1[key].value = ''
          }
          if (res.data && res.data.length > 0) {
            this.data1 = res.data
          } else {
            this.data1 = this.$options.data().data1
          }
        })
    },
    // 资产负债信息统计-金地合同接口
    getContractCount () {
      this.api
        .getContractCount({
          enterpriseId: this.enterpriseId,
          signTimeYearCount: this.tabs1[this.active2].key
        })
        .then((res) => {
          for (const key in this.data2) {
            this.data2[key].value = ''
          }
          if (res.data) {
            for (const key in this.data2) {
              for (const k in res.data) {
                if (this.data2[key].key === k) {
                  this.data2[key].value = res.data[k] / 10000
                }
              }
            }
          } else {
            for (const key in this.data2) {
              this.data2[key].value = ''
            }
          }
        })
    },
    // 资产负债信息统计-实控人资产接口
    getCompanyAssetCount () {
      this.api
        .getCompanyAssetCount({
          enterpriseId: this.enterpriseId,
          assetType: this.tabs2[this.active3].key
        })
        .then((res) => {
          for (const key in this.data3) {
            this.data3[key].value = ''
          }
          if (res.data) {
            for (const key in this.data3) {
              for (const k in res.data) {
                if (this.data3[key].key === k) {
                  if (k !== 'assetNum') {
                    this.data3[key].value = res.data[k]
                  } else {
                    this.data3[key].value = res.data[k]
                  }
                }
              }
            }
            this.modifyTime = formatDate(res.data.modifyTime, 'YY/MM/DD')
          } else {
            for (const key in this.data3) {
              this.data3[key].value = ''
            }
            this.modifyTime = formatDate('', 'YY/MM/DD')
          }
        })
    },
    // 资产负债信息统计-实控人负债接口
    getCompanyLiabilityCount () {
      this.api
        .getCompanyLiabilityCount({
          enterpriseId: this.enterpriseId,
          monthCount: this.tabs3[this.active4].key
        })
        .then((res) => {
          for (const key in this.data4) {
            this.data4[key].value = ''
          }
          if (res.data) {
            for (const key in this.data4) {
              for (const k in res.data) {
                if (this.data4[key].key === k) {
                  this.data4[key].value = res.data[k] / 10000
                }
              }
            }
            this.nextModifyTime = formatDate(res.data.modifyTime, 'YY/MM/DD')
          } else {
            for (const key in this.data4) {
              this.data4[key].value = ''
            }
            this.nextModifyTime = formatDate('', 'YY/MM/DD')
          }
        })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getFinancingCount()
    this.getContractCount()
    this.getCompanyAssetCount()
    this.getCompanyLiabilityCount()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
/deep/ .el-input--small .el-input__inner{
  text-align: center;
}
.time {
  position: absolute;
  bottom: 20px;
  right: 22px;

  font-size: 14px;

  color: #757380;
}
/deep/ .el-input {
  width: 25px;
  text-align: center;
}
/deep/ .el-input--small .el-input__inner {
  width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 0;
}
.label {
  margin: 0 auto;
  text-align: center;
}
.value {
  font-size: 24px;
  padding-top: 29px;
  color: #d1b28c;
}
.content {
  display: flex;
  justify-content: space-around;
  color: #38373a;
  font-size: 16px;
  padding-top: 84px;
}
.active {
  color: #fffefe !important;
  background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%) !important;
}
.tabs {
  padding: 0 18px;
  line-height: 31px;
  background: #fffdfa;
  border: 1px solid #f6b853;
  height: 31px;
  color: #d1b28c;
  font-size: 14px;
  cursor: pointer;
}
.title {
  display: flex;
  align-items: center;
  padding: 20px 20px 0 0;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  > div {
    width: 818px;
    height: 300px;
    border: 1px solid #f4ead2;
    margin-top: 20px;
    position: relative;
  }
}
</style>
