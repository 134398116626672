<template>
  <!-- 企业信息查看 -->
  <div class="content">
    <!-- <backTitle lable="供应商管理"></backTitle> -->
     <div class="back">
    <i class="iconfont iconfanhui1" @click="jumpBack()"></i
    ><span @click="jumpBack()">{{'访谈纪要详细' }}</span>
  </div>
    <div>
      <new-text-title index="01" label="基本信息"></new-text-title>
      <form-info
        :componentList="enterpriseConfig"
        :model="baseQueryParas"
        :showbor="true"
        :count="count"
      ></form-info>
    </div>
    <div class="topTabs">
      <div class="tabs">
        <div
          v-for="(item, index) in tabsTitles"
          :key="index"
          @click="clicTabs(index)"
          :class="{ activeClass: showMain === index }"
        >
          <div class="reg"></div>
          <div class="tipsImg" v-if="item.showTips">
            <img :src="successImg" v-if="item.pass" />
            <img :src="errorImg" v-if="!item.pass" />
          </div>
          {{ item.label }}
        </div>
      </div>
    </div>
    <!-- 基础数据 -->
    <div v-show="showMain === 0" style="background: #fff">
      <title-center :rules="false" name="账户信息" />
      <Basetable
        :columns="accountcolumns"
        :showPage="false"
        :tableAttrs="{
          data: accountData.records,
          stripe: true,
          'max-height': '500',
        }"
        ref="accounttableData"
        @pageSizeChange="accountpageSizeChange"
        @pageIndexChange="accountPageIndexChange"
        :webPage="true"
        :is-caculate-height="false"
        class="table"
      >
        <template slot="accountType" slot-scope="scope">
          <div>{{ setAccountType(scope.row.accountType) }}</div>
        </template>
      </Basetable>
      <title-center :rules="false" name="人员信息" />
      <Basetable
        :columns="personnelcolumns"
        :showPage="false"
        :tableAttrs="{
          data: personnelData.records,
          stripe: true,
          'max-height': '500',
        }"
        @expand-change="openPersonnel"
        ref="personnetableData"
        :webPage="false"
        :is-caculate-height="false"
        @pageSizeChange="personnelPageSizeChange"
        @pageIndexChange="personnelPageIndexChange"
        class="table"
      >
        <template slot="code" slot-scope="scope">
          <Basetable
            :columns="personnelRelationColumns"
            :showPage="false"
            :tableAttrs="{
              data: scope.row.personContactInfos,
              stripe: true,
            }"
            ref="personnetableData"
            :webPage="false"
            class="table"
          >
          </Basetable>
        </template>

        <template slot="dictId" slot-scope="scope">
          <div>{{ getPersonnelType(scope.row.dictId) }}</div>
        </template>
      </Basetable>
      <title-center :rules="false" name="文件信息" />
      <Basetable
        :columns="filefromconfig"
        :showPage="false"
        :tableAttrs="{
          data: fileFromtypeList,
          stripe: true,
        }"
        ref="filetableData"
        :data-source.sync="fileFromtypeList"
        :webPage="false"
        @expand-change="openResumeRow"
        :is-caculate-height="false"
        class="tableFile"
      >
        <template slot="code" slot-scope="scope">
          <Basetable
            :columns="fileList"
            :showPage="false"
            :tableAttrs="{
              data: scope.row.chide,
              stripe: true,
            }"
            ref="tableData"
            :webPage="false"
            :selfChangeHeight="120"
            class="childertable"
          >
            <template slot="action" slot-scope="scope">
              <IconButton
                content="预览"
                @click.prevent="fileview(scope.$index, scope.row, 'tab1')"
                icon="iconfont iconyulan"
              ></IconButton>
            </template>
          </Basetable>
        </template>
        <template slot="index" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <template slot="action" slot-scope="scope">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <div>
              <IconButton
                @click.prevent="
                  openFilesResume(scope.$index, scope.row, 'tab2')
                "
                content="查看履历"
                icon="iconfont iconlvli"
              ></IconButton>
            </div>
          </div>
        </template>
      </Basetable>
      <title-center :rules="false" name="财务信息" />
      <financial-information
        :financialData="financialData"
        @changeFinancialData="changeFinancialData"
        type="details"
      ></financial-information>
    </div>
    <!-- 变更司法信息 -->
    <div v-show="showMain === 1" style="background: #fff">
      <title-center :rules="false" name="变更记录" />
      <Basetable
        :columns="changeRecordColumn"
        :tableAttrs="{
          data: changeRecordData,
          stripe: true,
        }"
        :api="supplierChange"
        :getApi="'getPage'"
        :dataSource.sync="changeRecordData"
        :loadCount="loadCount1"
        :queryParas="queryParas1"
        :isCaculateHeight="false"
        :showPage="true"
        :webPage="false"
      >
      </Basetable>
      <title-center :rules="false" name="被执行人记录" />
      <Basetable
        :columns="executorRecordColumn"
        :tableAttrs="{
          data: executorRecordData,
          stripe: true,
        }"
        :api="supplierExecutor"
        :getApi="'getPage'"
        :dataSource.sync="executorRecordData"
        :loadCount="loadCount2"
        :queryParas="queryParas2"
        :isCaculateHeight="false"
        :showPage="true"
        :webPage="false"
      >
        <template slot="fileId" slot-scope="scope">
          <!-- 文件列按钮组 -->
          <file-button-group
            :data="scope.row.finAttachmentList"
            @dowload="dowloadFile"
            :openUpload="false"
          />
        </template>
      </Basetable>
      <title-center :rules="false" name="限制消费令记录" />
      <Basetable
        :columns="consumptionRecordColumn"
        :tableAttrs="{
          data: consumptionRecordData,
          stripe: true,
        }"
        :api="supplierLimitConsumption"
        :getApi="'getPage'"
        :dataSource.sync="consumptionRecordData"
        :loadCount="loadCount3"
        :queryParas="queryParas3"
        :isCaculateHeight="false"
        :showPage="true"
        :webPage="false"
      >
        <template slot="fileId" slot-scope="scope">
          <!-- 文件列按钮组 -->
          <file-button-group
            :data="scope.row.finAttachmentList"
            @dowload="dowloadFile"
            :openUpload="false"
          />
        </template>
      </Basetable>
      <title-center :rules="false" name="涉诉记录" />
      <litigation-information
        :litigationListData.sync="litigationListData"
        @changeLitigationListData="changeLitigationListData"
        :enterpriseName="baseQueryParas.enterpriseName"
        type="details"
      />
    </div>

    <!-- 资产负债信息 -->
    <div v-show="showMain === 2" style="background: #fff">

    <liability-information />
    <!-- 文件预览 -->
    <pre-view-new
      :count="Count"
      :fileId="fileId"
      :fileType="fileType"
      :isOpen="true"
      ref="preView"
    />
    <!-- 文件履历弹窗  -->
    <files-resume-dialog
        :visible.sync="visibleFilsResume"
        :filesResumeData="filesResumeData"
        :fileTypeName="fileTypeName"
      ></files-resume-dialog>
    </div>
  </div>
</template>

<script>
import LiabilityInformation from '../components/liability-information.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import TitleCenter from '@/pages/business/components/title-center.vue'
import {
  supplierApi,
  supplierApiPage,
  supplierFileApi,
  analysisApi,
  litigationApi,
  supplierChange,
  supplierLimitConsumption,
  supplierExecutor
} from '@/api/companyApi'
import Storage from '@/utils/storage'
import { getDictNameMaps, getDictLists } from '@/filters/fromDict'
import FinancialInformation from '@/pages/basic-data-management/supplier-management/components/financial-information.vue'
import LitigationInformation from '@/pages/basic-data-management/supplier-management/components/litigation-information.vue'
import Basetable from '@/components/common/table/base-table/base-table'
import PreViewNew from '@/components/pre-view/pre-view.vue'
import FilesResumeDialog from '@/pages/basic-data-management/supplier-management/components/files-resume-dialog.vue'
import {
  enterpriseConfig,
  accountcolumns,
  personnelcolumns,
  personnelRelationColumns,
  filefromconfig,
  fileList,
  changeRecordColumn,
  executorRecordColumn,
  consumptionRecordColumn
} from '../utils/make-config'
// import BackTitle from '@/pages/business/components/backtitle.vue'
import NewTextTitle from '@/components/packages/text-title/text-title'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import { downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import FileButtonGroup from '@/pages/basic-data-management/supplier-management/components/new-file-button-group.vue'
export default {
  components: {
    // BackTitle,
    NewTextTitle,
    FormInfo,
    Basetable,
    FinancialInformation,
    TitleCenter,
    IconButton,
    LitigationInformation,
    LiabilityInformation,
    PreViewNew,
    FilesResumeDialog,
    FileButtonGroup
  },
  props: {},
  data () {
    return {
      supplierChange,
      supplierLimitConsumption,
      supplierExecutor,
      count: 0,
      litigationListData: [],
      changeRecordData: [],
      executorRecordData: [],
      consumptionRecordData: [],
      queryParas1: {
        pageSize: 10,
        pageIndex: 1,
        enterpriseId: Storage.getLocal('admittanceInfo').gysId
      },
      queryParas2: {
        pageSize: 10,
        pageIndex: 1,
        enterpriseId: Storage.getLocal('admittanceInfo').gysId
      },
      queryParas3: {
        pageSize: 10,
        pageIndex: 1,
        enterpriseId: Storage.getLocal('admittanceInfo').gysId
      },
      loadCount1: 0,
      loadCount2: 0,
      loadCount3: 0,
      TemplateData: {
        year: '',
        cashEquivalents: '',
        accountsReceivable: '',
        otherReceivables: '',
        prepayment: '',
        totalAssets: '',
        totalLiability: '',
        primeOperatingRevenue: '',
        netProfit: '',
        netProfitRate: '',
        debtAssetRatio: '',
        receivablesTurnoverRatio: '',
        cashRatio: ''
      }, // 财务信息模板数据
      bankType: [],
      childrenFileList: [],
      tabRow: {},
      colIndex: 0,
      baseQueryParas: {},
      financialData: [],
      fileFromtypeList: [],
      personnelData: { records: [] },
      accountData: { records: [] },
      showMain: 0,
      tabsTitles: [
        { label: '基础数据', pass: true, showTips: false },
        { label: '变更及司法信息', pass: true, showTips: false },
        { label: '资产负债信息', pass: true, showTips: false }
      ],

      currentData: {
        summaryNum: '测试1'
      },
      Count: 0,
      fileId: '',
      fileType: '',
      filesResumeData: [],
      fileTypeName: '',
      visibleFilsResume: false
    }
  },
  // 计算属性 类似于data概念
  computed: {
    consumptionRecordColumn () {
      return consumptionRecordColumn(this)
    },
    executorRecordColumn () {
      return executorRecordColumn(this)
    },
    changeRecordColumn () {
      return changeRecordColumn(this)
    },
    litigationApi () {
      return litigationApi
    },
    analysisApi () {
      return analysisApi
    },
    supplierFileApi () {
      return supplierFileApi
    },
    api () {
      return supplierApiPage
    },
    supplierApi () {
      return supplierApi
    },
    fileList () {
      return fileList(this)
    },
    filefromconfig () {
      return filefromconfig(this)
    },
    personnelRelationColumns () {
      return personnelRelationColumns(this)
    },
    personnelcolumns () {
      return personnelcolumns(this)
    },
    accountcolumns () {
      return accountcolumns(this)
    },
    enterpriseConfig () {
      return enterpriseConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    jumpBack () {
      this.$router.push({
        path: '/CRM/interviewMinutesMake',
        query: {
          keyId: this.$route.query.keyId,
          type: 'edit'
        }
      })
    },
    // 改变诉讼信息
    changeLitigationListData (data) {
      this.litigationListData.push.apply(this.litigationListData, data)
      const result = []
      const obj = {}
      for (var i = 0; i < this.litigationListData.length; i++) {
        if (!obj[this.litigationListData[i].caseNumber]) {
          result.push(this.litigationListData[i])
          obj[this.litigationListData[i].caseNumber] = true
        }
      }
      this.litigationListData = result
      console.log(result)
    },
    // 获取诉讼信息
    getLitigationList () {
      const params = {
        companyId: Storage.getLocal('admittanceInfo').gysId
      }
      this.litigationApi.getLitigationList(params).then((res) => {
        this.litigationListData = res.data
        console.log(res.data, 'res,data')
      })
    },
    /**
     * 返回账户类型
     * @param id
     * @returns {*}
     */
    setAccountType (id) {
      const data = getDictLists('SUPPLIER_BANK_TYPE').find(
        (item) => parseInt(item.dictId) === parseInt(id)
      )
      if (data) {
        return data.dictName
      }
    },
    /**
     * 获取账号信息的账号类型
     */
    getAccountType () {
      const data = getDictLists('SUPPLIER_BANK_TYPE')
      if (data) {
        data.forEach((item) => {
          this.bankType.push({
            dictName: item.dictName,
            dictId: item.dictId
          })
        })
      }
    },
    // 获取财务信息列表
    getAnalysisList () {
      const params = {
        companyId: Storage.getLocal('admittanceInfo').gysId
      }
      this.analysisApi.getAnalysisList(params).then((res) => {
        if (res.data) {
          this.financialData = this.formatAnalysisList(res.data)
          console.log(this.financialData, '222')
        }
      })
    },
    /**
     * 附件信息获取所有文件
     * @param data
     */
    getAllfile (data) {
      data.forEach((item) => {
        const params = {
          configItemCode: item.configItemCode,
          configSetCode: item.configSetCode,
          enterpriseId: this.$route.query.keyId || ''
        }
        this.childrenFileList = []
        this.supplierFileApi
          .getsupRecordList(params)
          .then((res) => {
            if (res) {
              this.childrenFileList = [...this.childrenFileList, ...res.data]
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    /**
     * 附件信息获取文件模板
     */
    getFileTemplate () {
      this.supplierApi
        .getsupFileList({ configSetCode: 'F_GYS_BASEFILE' })
        .then((res) => {
          if (res.data) {
            res.data.map((item) => {
              item.chide = []
            })
            this.fileFromtypeList = res.data
            this.getAllfile(res.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    openResumeRow (row, expandedRows) {
      if (expandedRows.length > 0) {
        this.showCurrentFile(row)
      }
      this.tabRow = row
    },
    /**
     * 附件信息文件预览
     * @param index
     * @param row
     * @param tab
     * @returns {ElMessageComponent}
     */
    fileview (index, row, tab) {
      // yy
      if (!this.tabRow.chide || this.tabRow.chide.length <= 0) {
        this.$message.error('没有文件，不能预览')
        return
      }
      if (row && tab === 'tab2') {
        this.colIndex = index
        if (!this.fileData[index]) {
          return this.$message.error('没有文件，不能预览')
        }
        const att = this.fileData[index]
        if (att) {
          const FileExt = att.fileName.replace(/.+\./, '')
          this.fileId = att.fileId
          this.fileType = FileExt
        }
      }

      if (row && tab === 'tab1') {
        const FileExt = row.fileName.replace(/.+\./, '')
        this.fileId = row.fileId
        this.fileType = FileExt
      }
      this.$nextTick(() => {
        this.Count++
      })
    },
    // 查看文件履历
    async openFilesResume (index, data) {
      const params = {
        enterpriseId: this.$route.query.keyId,
        configItemCode: data.configItemCode
      }
      try {
        const res = await supplierApi.getFileList(params)
        this.filesResumeData = res.data
      } catch (err) {
        console.log(err)
      }
      this.fileTypeName = data.displayName
      this.visibleFilsResume = true
    },
    // 获取当前文件下的列表
    async showCurrentFile (data) {
      this.colIndex = this.fileFromtypeList.findIndex(
        (item) =>
          data.configItemCode === item.configItemCode &&
          data.configSetCode === item.configSetCode
      )
      const params = {
        configItemCode: data.configItemCode,
        configSetCode: data.configSetCode,
        enterpriseId: Storage.getLocal('admittanceInfo').gysId
      }
      const res = await this.supplierFileApi.getsupRecordList(params)
      this.fileFromtypeList[this.colIndex].chide = res.data
    },
    /**
     * 人员类型筛选
     * @param type
     * @returns {string}
     */
    getPersonnelType (type) {
      const newValue = []
      const data = getDictNameMaps('PERSONNEL_TYPE')
      if (data) {
        type.forEach((item) => {
          for (const [key, value] of Object.entries(data)) {
            if (item === value) {
              newValue.push(key)
            }
          }
        })
        return newValue.join(',')
      }
    },
    /**
     * 数据初始化
     */
    initData () {
      const keyId = Storage.getLocal('admittanceInfo').gysId
      if (keyId) {
        this.supplierApi.get(keyId).then((res) => {
          this.baseQueryParas = res.data.companyInfo
          this.count++
          this.$set(this.baseQueryParas, 'locationCity', this.baseQueryParas.manageProvince + this.baseQueryParas.manageCity)
          // 选中适用资金方列表数据
          if (res.data.companyInfo.supplierAuthCapitalList) {
            const supplierAuthCapitalList =
              res.data.companyInfo.supplierAuthCapitalList
            this.$set(this.baseQueryParas, 'capitalSideCodeList', [])
            supplierAuthCapitalList.forEach((ele) => {
              this.baseQueryParas.capitalSideCodeList.push(ele.capitalSideCode)
            })
          }
          this.easQueryParas = res.data.easInfoList
          this.accountData.records = res.data.bankAccountList || []
          this.personnelData.records = res.data.personnelList || []
          this.personnelData.records.forEach((item, index) => {
            if (item.dictId) {
              item.dictId = item.dictId.split(',')
            }
            if (!item.personContactInfos) {
              this.$set(
                this.personnelData.records[index],
                'personContactInfos',
                []
              )
            }
          })
          this.easFileList = res.data.easfileList
          this.fileData = res.data.fileList
          // 人员，账户信息总条数
          this.$refs.accounttableData.total = this.accountData.total || 0
          this.$refs.personnetableData.total = this.personnelData.total || 0
          this.editTable()
        })
      }
    },
    /**
     * 人员信息，账户信息回显修改
     */
    editTable () {
      if (this.accountData.records) {
        this.accountData.records.map((item) => {
          item.accountType = item.accountType.toString()

          return item
        })
      }
      if (this.personnelData.records) {
        this.personnelData.records.map((item) => {
          return item
        })
      }
    },
    // 改变财务信息
    changeFinancialData (data) {
      const oldArr = JSON.parse(JSON.stringify(this.financialData))
      const indexArr = []
      oldArr.forEach((item, index) => {
        data.forEach((elem) => {
          if (item.year === elem.year) {
            indexArr.push(index)
          }
        })
      })
      const newArr = []
      oldArr.forEach((item, index) => {
        if (indexArr.indexOf(index) < 0) {
          newArr.push(item)
        }
      })
      newArr.push.apply(newArr, data)
      this.financialData = this.formatAnalysisList(newArr)
    },
    // 财务信息数据格式化
    formatAnalysisList (data) {
      const newArr = []
      data.forEach((item) => {
        const obj = {}
        for (const key in this.TemplateData) {
          for (const j in item) {
            if (key === j) {
              obj[key] = JSON.parse(JSON.stringify(item[j]))
            }
          }
        }
        newArr.push(obj)
      })
      return newArr
    },
    /**
     * 人员信息页码
     * @param data
     */
    personnelPageSizeChange (data) {
      this.peranLqueryParas.pageSize = data
      this.getPersonnelPage()
    },
    /**
     * 跳转到指定页码
     * @param data
     */
    personnelPageIndexChange (data) {
      this.peranLqueryParas.pageIndex = data
      this.getPersonnelPage()
    },
    // 查看亲密关系
    openPersonnel (row, expandedRows) {
      console.log(row)
    },
    accountpageSizeChange () {},
    accountPageIndexChange () {},
    clicTabs (index) {
      this.showMain = index
    },
    // 获取企业变更记录
    getSupplierChangeData () {
      supplierChange.getPage({
        pageSize: 9999,
        pageIndex: 1,
        enterpriseId: Storage.getLocal('admittanceInfo').gysId
      }).then(res => {
        this.inFinancingData.changeRecordData = res.data.records
      })
    },
    // 限制消费令记录
    getLimitConsumption () {
      supplierLimitConsumption.getPage({
        pageSize: 9999,
        pageIndex: 1,
        enterpriseId: Storage.getLocal('admittanceInfo').gysId
      }).then(res => {
        this.inFinancingData.consumptionRecordData = res.data.records
      })
    },
    // 被执行人记录
    getExecutorData () {
      supplierExecutor.getPage({
        pageSize: 9999,
        pageIndex: 1,
        enterpriseId: Storage.getLocal('admittanceInfo').gysId
      }).then(res => {
        this.inFinancingData.executorRecordData = res.data.records
      })
    },
    dowloadFile (data) {
      const fileId = data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initData()
    this.getFileTemplate()
    this.getAnalysisList()
    this.getAccountType()
    this.getLitigationList()
    this.$nextTick(() => {
      this.loadCount1++
      this.loadCount2++
      this.loadCount3++
    })
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.topTabs {
  padding: 10px 0 0 0;
  margin-bottom: 10px;
  background: #f9f9f6;
}
.tabs {
  width: 430px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  // margin: 20px;
  .activeClass {
    background: #EBF1FF !important;
    font-weight: 600;
  }
  > div {
    flex: 1;
    box-sizing: border-box;
    // border-bottom: 3px solid red;
    height: 100%;
    width: 120px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: #ffffff;
    color: #2D363E;
    position: relative;
    .tipsImg {
      position: absolute;
      top: 3px;
      left: 2px;
      width: 12px;
      height: 12px;
      img {
        width: 100%;
        position: absolute;
      }
    }
    .reg {
      height: 0;
      width: 0;
      border-top: 40px solid #f9f9f6;
      border-left: 22px solid transparent;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.back {
  padding: 9px 15px;

  display: flex;
  align-items: center;
  i {
    color: #96B5FF;
    font-size: 20px;
    cursor: pointer;
    display: block;
  }
  span {
    display: block;
    padding-left: 10px;
    font-size: 12px;
    color: #757380;
    cursor: pointer;
    font-weight: 400;
  }
}
</style>
