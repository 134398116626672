<template>
  <div class="iconRow">
    <div class="leftIcon">
      <i :class="$attrs.icon"></i>
    </div>
    <div class="label">{{ $attrs.label }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {}
  },
  // 计算属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.iconRow {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left:20px ;

}
.label {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2862E7;
  padding-left: 16px;
}
.leftIcon {
  width: 40px;
  height: 40px;
  background: #ffe7dd;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;

  .iconfont {
    font-size: 24px !important;
    color: #2862E7 !important;
  }
}
</style>
